import { useTranslation } from "react-i18next";
import "./styles.scss";
import { Translations } from "i18n/i18n";

export const HowItWorks = () => {
  const { t } = useTranslation();

  return (
    <div className="main-works mt-5">
      <h1>{t(Translations.HowItWorks)}</h1>
      <div className="works-video mt-2">
        <iframe
          width="100%"
          height="700"
          src="https://www.youtube.com/embed/s4BibernJxU?si=1a_E4idPUpj5FW3B"
          title="YouTube video player"
          frameBorder={0}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen={undefined}
        ></iframe>
      </div>
    </div>
  );
};
